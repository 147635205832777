/*=============================================================================================
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Your Name
==============================================================================================*/

/* general
----------------------------------------------------------------------------------------------*/
@import "../milligram/Color";    // import your general color from milligram
@import "../milligram/Variables";
/* general
----------------------------------------------------------------------------------------------*/
@import "../component/layout";
@import "../pages/general";

.misc{
  min-height: 500px;
  width: calc(100% - 87px);
  h2{
    color: #374156;
    i{
      font-weight: 400;
    }
  } 
  .wrapper{
  }
  .wrap-figure{
    display: flex;
    &__left{
      display: flex;
      margin: 0 -12px;
      figure{
        padding: 0 12px;
        box-sizing: border-box;
      }
    }
    &__right{
      align-self: center;
      padding-left: 100px;
      box-sizing: border-box;
    }
  }
  &.page404{
    padding: 200px 0 110px;
    position: relative;
    &::before{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background: url('/images/material/bg-misc.svg')no-repeat center;
      width: 380px;
      height: 470px;
    }
   
  }
  &.ty{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 250px 0 230px;
    position: relative;
    &::before{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background: url('/images/material/bg-misc.svg')no-repeat center;
      width: 380px;
      height: 470px;
    }
    .center{
      text-align: center;
    }
  }
  &.undermaintenance{
    height: 100vh;
    width: auto;
    min-height: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-size: cover;
    position: relative;
    &::before{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 410px;
      background: url('/images/material/bg-under.png')no-repeat top;
      background-size: cover;
    }
    .head{
      margin-top: 100px;
    }
    .text{
      max-width: 440px;
      text-align: center;
      position: relative;
      z-index: 3;
      p{
        font-weight: 500;
        color: #333333;
        a{
          font-weight: bold;
        }
      }
     
    }
    .input{
      max-width: 328px;
      margin: 0 auto;
      input{
        background: #fff;
        border: 1px solid rgba($color: #000000, $alpha: 0.2);
      }
      input,button{
        width: 100%;
      }
    }
    .captcha {
      margin-top: 10px;
    }
    .foot{
      text-align: center;
      margin-bottom: 40px;
      position: relative;
      z-index: 2;
      p{
        margin-bottom: 0;
      }
    }
    .medsos{
      margin-bottom:24px ;
      a{
        margin: 0 8px;
      }
    }
    button{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 24px;
      &::before{
        position: relative;
        top: auto;
        right: auto;
        order: 2;
        margin-left: 8px;
      }
    }
  }
}

.search{
  padding: 169px 0 148px;
  .center{
    text-align: center;
    margin: 0 auto;
    a{
      display: inline-flex;
      align-items: center;
      svg{
        display: inline-flex;
      }
      i{
        margin-left: 8px;
      }
    }
  }
  .title{
    max-width: 680px;
    h2{
      font-size: 3.5rem;
      text-transform: uppercase;
    }
  }
  .filter{
    display: flex;
    align-items: center;
    margin: 80px 0 40px;
    &__right{
      margin-left: auto;
      min-width: 148px;
      select{
        margin-bottom: 0;
      }
    }
  }
  .listing{
    margin-left: -12px;
    width: calc(100% + 24px);
    .column{
      margin-bottom:40px;
      padding: 0 12px;
      .inner{
        padding: 16px;
        box-sizing: border-box;
        transition: .2s all ease-out;
      }
      p{margin-bottom: 0;}
      h4{
        transition: .2s all ease-out;
        color: #374156;
      }
      &:hover{
        h4{
          color: #FFC845;
        }
        .inner{
          background: #FFF;
          box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
        }
      }
    }
  h3{
      text-transform: uppercase;
      b{
        word-break: break-all;
      }
    }
  }
  .input-search{
    input{
      background:#FBFBFB url('/images/material/icon/ic-search-s.svg')no-repeat 97% center;
      padding-right: 40px;
      margin-bottom: 0;
      border-bottom: 1px solid #F2EFE8;
    }
  }
  .text-info{
    margin-top: 40px;
    figure{
      margin-bottom: 16px;
    }
    h3{
      text-transform: uppercase;
      margin-bottom: 16px;
    }
    h4 {
      font-size: 1.5rem; 
    }
  }
}

@media all and (max-width: 1200px) {

  .misc{
    width: calc(100% - 72px);
    &.page404 {
      padding: 180px 0 132px;
      &::before {
        width: 268px;
        height: 400px;
        background-size: 100% 100%; 
      }
    }
    &.undermaintenance {
      .head {
        margin-top: 28px;
      }
      .text {
        max-width: 420px;
      }
    }
    &.ty {
      &::before {
        width: 268px;
        height: 400px;
        background-size: 100% 100%; 
      }
    }
    .wrap-figure{
      &__right{
        padding-left: 40px;
      }
    }
  }
  .search{
    padding: 165px 0 70px;
    .filter {
      margin: 64px 0 40px; 
    }
    .title{
      h2{
        font-size: 2.5rem;
        margin-bottom: 40px;
      }
    }
    .listing{
      .column {
        h4 {
          font-size: 1.5rem; 
        }
        &.column-25{
          flex: 0 0 33.333%;
          max-width: 33.333%;
          margin-bottom: 24px;
        }
      }
    }
    .text-info {
      margin-bottom: 70px;
    }
  }
}

@media all and (max-width: 1023px) {
  .misc{
    width: calc(100% - 72px);
    .wrap-figure{
      flex-direction: column;
      &__left {
        figure {
          max-width: 180px;
        }
      }
      &__right{
        padding-left: 0;
        align-self: flex-start;
        margin-top: 40px;
        max-width: 352px; 
      }
    }
    &.page404{
      padding: 160px 0 100px; 
      &::before{display: none;}
    }
    &.undermaintenance{
      .text{
        figure{
         img{
          width: 140px;
         }
        }
      }
    }
    &.ty {
      &::before {
        display: none; 
      }
    }
  }
  header{
    .wrapper{
      .mobile{
        button{
          border-color: #000;
          color: #000;
          background-color: #fff;
        }
        .burger-menu{
          span{
            background-color: #000;
          }
        }
      }
    }
  }
  .search{
    .filter {
      margin: 40px 0; 
    }
    .listing{
      h3{
        font-size: 28px;
      }
    }
    .text-info {
      margin: 80px 0;      
    }
  }
}

@media all and (max-width: 767px) {
  .misc{
    width: 100%;
    .wrap-figure{
      width: 100%;
      &__left{
        width: auto;
        margin: 0 -8px;
        figure{
          width: 50%;
          padding: 0 8px;
          img{
            width: 100%;
          }
        }
      }
      &__right {
        margin-top: 24px; 
      }
    }
    &.ty{
      padding: 240px 0 140px; 
      p{
        font-size: 16px;
      }
    }
    &.page404{
      padding: 120px 0 70px;
    }
    &.undermaintenance{
      min-height: 100vh;
      height: auto; 
      .head{
        margin-top: 40px;
      }
      .text{
        max-width: 100%;
        margin: 70px 24px; 
        h2{
          font-size: 2rem;
          margin-bottom: 24px; 
        }
      }
      .input{
        max-width: 100%;
      }
      .foot{
        margin: 0 40px 40px;
      }
    }
  }
  .search{
    padding: 110px 0 100px;
    .filter{
      margin: 35px 0 40px;
    }
    .title{
      h2{
        font-size: 2rem;
        margin-bottom: 32px; 
      }
    }
    .listing{
      h3{
        font-size: 24px;
      }      
      .column {
        h4 {
          font-size: 1.25rem; 
        }
        &.column-25{
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
    .text-info {
      margin: 46px 0; 
    }
  }
}
